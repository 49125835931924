import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';

import { MAP_API_KEY, APP_URL, DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from 'config';
import NotFound from 'components/Pages/404';
import MapMarker from 'components/Pages/EmbeddedMap/MapMarker';
import { CopyToClipboard } from 'components/Icons';
import MarkerInfoWindow from 'components/Pages/EmbeddedMap/MarkerInfoWindow';

const googleMapReactConfig = {
  bootstrapURLKeys: { key: MAP_API_KEY },
  options: { scrollwheel: false, panControl: true },
};

export const INFO_WINDOW_STATE_TYPES = {
  CLOSE: 'close',
  MINIMIZED: 'minimized',
  OPEN: 'open',
};

export const DEFAULT_INFO_WINDOW_STATE = {
  state: INFO_WINDOW_STATE_TYPES.CLOSE,
  lat: null,
  lng: null,
  transactions: null,
  zipCode: null,
};

function Map({ isEmbedded }) {
  const { nameSlug } = useParams();

  const [infoWindow, setInfoWindow] = useState(DEFAULT_INFO_WINDOW_STATE);

  const mapRef = useRef();
  const { transactions, error } = useSelector(state => state.agentProfile);

  const copyEmbededMapCodeToClipboard = () => {
    const embededLink = `${APP_URL}/embed_officers_map/${nameSlug}`;
    const embededCode = `<iframe src="${embededLink}" width="720px" height="480px"></iframe>`;
    navigator.clipboard.writeText(embededCode);
  };

  const closeInfoWindow = () => {
    setInfoWindow(DEFAULT_INFO_WINDOW_STATE);
  };

  const renderMarkers = data =>
    _.map(data, (transactions, zipCode) => (
      <MapMarker
        key={zipCode}
        zipCode={zipCode}
        transactions={transactions}
        mapRef={mapRef}
        lat={transactions[0].latitude}
        lng={transactions[0].longitude}
        infoWindow={infoWindow}
        setInfoWindow={setInfoWindow}
      />
    ));

  if (transactions === null && error) {
    return <NotFound />;
  }

  if (!transactions) {
    return <div />;
  }

  // Assuming reviews is already defined as an array of review objects
  const lats = _.map(transactions, (reviews, zipCode) => parseFloat(reviews[0].latitude));
  const longs = _.map(transactions, (reviews, zipCode) => parseFloat(reviews[0].longitude));

  const maxLat = Math.max(...lats);
  const minLat = Math.min(...lats);
  const avgLat = (maxLat + minLat) / 2;

  const maxLong = Math.max(...longs);
  const minLong = Math.min(...longs);
  const avgLong = (maxLong + minLong) / 2;

  // const ipLocation = JSON.parse(localStorage.getItem('ipLocation'));
  const position = { lat: avgLat, lng: avgLong };
  // if (ipLocation) {
  //   position = { lat: ipLocation.coordinates.lat, lng: ipLocation.coordinates.long }; // IP LatLng
  // }
  console.log(position)
  return (
    <div className={`map ${isEmbedded ? 'embed' : ''}`}>
      <GoogleMapReact
        ref={mapRef}
        defaultCenter={position}
        defaultZoom={DEFAULT_MAP_ZOOM}
        onClick={closeInfoWindow}
        {...googleMapReactConfig}
      >
        {renderMarkers(transactions)}

        <MarkerInfoWindow {...infoWindow} setInfoWindow={setInfoWindow} />
      </GoogleMapReact>

      {!isEmbedded && (
        <div id='copy-embeded-code' onClick={copyEmbededMapCodeToClipboard}>
          <CopyToClipboard />
        </div>
      )}
    </div>
  );
}

export default Map;

